import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalsProvider } from './globals-provider';
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
declare let require: any;

import WalletConnectProvider from "@walletconnect/web3-provider";

const Web3 = require('web3');
var ENS = require("ethereum-ens");
declare let window: any;

@Injectable()
export class Web3Provider {
  public web3: any;
  public accounts: string[];
  public address: string = "";
  public displayAddress: any = {ens: "", full : "", abbr: ""};
  public connected = false;
  public connecting = false;
  public connectionDenied = false;
  public accountsObservable = new Subject<string>();
  public provider;
  public ens;

  constructor(
    public globals: GlobalsProvider) {
  }

  async connect(wallet = true, doNotPrompt = false) : Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if(!this.connected){
        if(wallet)
        await this.connectWithWallet(doNotPrompt);
      else
        await this.connectWithoutWallet();
      }
      else
        await this.refreshAccounts();    

      resolve(this.connected);
    })
  }

  async connectWithWallet(doNotPrompt) : Promise<void>{
    return new Promise(async (resolve, reject) => {
          //track that we are currently connecting
          this.connecting = true;

          var connectWeb3 = false;
          if(window.ethereum){
            connectWeb3 = true
          }
          else if(!doNotPrompt) {
    
            try{
    
              //  Create WalletConnect Provider
              this.provider = new WalletConnectProvider({
                rpc: {
                  1: this.globals.thirdpartyEndpoint(),
                  4: this.globals.thirdpartyEndpoint(),
                  100: "https://dai.poa.network",
                  // ...
                },
                infuraId: this.globals.infuraProjectId,
                qrcodeModalOptions: {
                  mobileLinks: [
                    "rainbow",
                    "metamask",
                    "argent",
                    "trust",
                    "imtoken",
                    "pillar",
                  ],
                },
              });
      
              //  Enable session (triggers QR Code modal)
              await  this.provider .enable();
      
              this.provider .on("accountsChanged", (accounts: string[]) => {
                console.log("Accounts changed: " + accounts);
                this.updateWeb3Connection(accounts);
              });
              
              this.web3 = new Web3(this.provider);
              const accounts = await this.web3.eth.getAccounts();
              this.globals.noWallet = false;
              this.updateWeb3Connection(accounts, this.provider);
            }
            catch(ex){
              console.log(ex);
              connectWeb3 = true;
              this.connectionDenied = true;
            }
          }
    
          if(connectWeb3)
           this.connected = await this.connectToWeb3Wallet();
    
           if(!this.connected)
             await this.connectWithoutWallet()

  
          this.connecting = false
          resolve();
        });
  }

  async connectWithoutWallet() :Promise<void> {
    return new Promise(async (resolve, reject) => {
      if(!this.web3)
        this.web3 = await createAlchemyWeb3(this.globals.thirdpartyEndpoint());

      this.globals.noWallet = true;
      this.connected = false;
      resolve();
    });
  }

  async connectToWeb3Wallet(): Promise<boolean>{

    return new Promise(async (resolve, reject) => {

      var connected = false;

      if(window.ethereum){
        //use Alchemy provider
        if(!this.web3)
          this.web3 = await createAlchemyWeb3(this.globals.thirdpartyEndpoint());

        connected = await this.refreshAccounts();

        if(this.connected){
          this.globals.noWallet = false;
        }
      }

    resolve(connected)

  });

  }

  updateWeb3Connection(accounts, provider = null){
    if(accounts.length > 0){
      this.accounts = accounts;
      this.web3.eth.defaultAccount = this.accounts[0];
      this.address = this.accounts[0];
      this.ens = new ENS(provider ? provider : window.ethereum);
      this.updateDisplay();
      this.connected = true;
      this.accountsObservable.next(this.web3.eth.defaultAccount);
    }
  }

  public async refreshAccounts() : Promise<boolean>{
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        window.ethereum.request({ method: 'eth_requestAccounts' })
          .then(async (accounts) => {
            this.updateWeb3Connection(accounts);
            resolve(true);
          })
          .catch(reason => {
            console.log(reason);
            resolve(false);
          });
      }
      else
        resolve(false)
    });
  }

  disconnect() {
    if (this.web3)
      this.web3.on('accountsChanged', (code: number, reason: string) => {
        const accountSwitch = code[0];
        if (accountSwitch) {

        } else {
          this.connected = false;
        }

      });
  }

  async updateDisplay() {

    setTimeout(async () => {
      this.displayAddress = await this.reverseEnsLookup(this.address);
    }, 500)
  }

  resolveEns(ensName): Promise<string>{
    return this.web3.eth.ens.getAddress(ensName);
  }

  async reverseEnsLookup(address) {
    var name = address;
    var ensStr = ""
    if(this.connected){
      this.ens = new ENS(this.provider ? this.provider : window.ethereum);

    if (this.ens) {
      try {
        ensStr = await this.ens.reverse(address).name();
      } catch (ex) {
        name = address;
      }
    }
    else{
        name = address;
       }
    }

    return {ens : ensStr, full: name, abbr: name.substring(2, 8) + ".."};
  }

  getSpecificWeb3(chain, environment){
    var contractConfig = this.globals.contractAddressMapping.filter(m => m.environment == environment && m.chain == chain)[0];
    return chain == "avalanche" ? new Web3(contractConfig.dataEndpoint) : createAlchemyWeb3(contractConfig.dataEndpoint);
  }
}
